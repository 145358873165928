<template>
  <div :style="background" class="login">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100">
        <CCol col="12">
          <CCard v-if="resetStringValidated" class="p-0 m-auto">
            <CCardBody class="p-4">
              <CRow>
                <CCol md="12" lg="12" class="pt-0 pb-0">                  
                  <div v-if="resetStringValid">
                    <div v-if="!passwordResetSuccessful">
                      <h1 class="mb-2">{{$t('registration.Welcome_at')}} {{environmentVariables.environment_name}} 👋🏽</h1>
                      <p class="mt-0 mb-2">{{ $t('registration.Time_to_set_your_' + environmentVariables.environment_tag + '_password') }}</p>

                      <CForm @submit.prevent="login">
                        <span><strong>{{ $t('registration.Your_desired_password') }}</strong></span>
                        <CInput class="mt-2 mb-2" :placeholder="$t('login.Password')" type="password" v-model="resetPasswordData.password1"></CInput>
                        <span><strong>{{ $t('registration.Your_desired_password_again') }}</strong></span>
                        <CInput class="mt-2 mb-0" :placeholder="$t('login.Password')" type="password" v-model="resetPasswordData.password2"></CInput>
                        <span class="d-block mt-1"><i>{{ $t('login.Minimum_length') }}</i></span>
                        <CRow>
                          <CCol md="12" lg="12" class="pb-0">
                            <CButton @click="resetPassword()" color="primary" class="w-100" :disabled="!resetPasswordData.password1 || !resetPasswordData.password2">
                              <i class="fas fa-check mr-2"/>{{ $t('registration.Save_password') }}
                            </CButton>
                          </CCol>
                          <CCol v-if="resetPasswordErrorMessage" col="12" class="pb-0 text-center">
                            <p class="m-0">{{ resetPasswordErrorMessage }}</p>                        
                          </CCol>
                        </CRow>
                      </CForm>
                    </div>
                    <div v-else>
                      <CRow>
                        <CCol md="12" lg="12" class="pt-0 pb-0">
                          <p class="mt-0 mb-3">{{ $t('common.Loading') }}</p>
                        </CCol>
                      </CRow>                        
                    </div>                    
                  </div>
                  <div v-else>
                    <CRow>
                      <CCol md="12" lg="12" class="pt-0 pb-0">
                        <h1 class="mb-3">{{ $t('login.Reset_link_unavailable') }}</h1>
                        <p class="mt-0 mb-3">{{ $t('login.Password_reset_link_unavailable') }}</p>
                        <CButton @click="openLoginPage()" color="primary" class="w-100">
                          {{ $t('login.Return_to_login_page') }}<i class="fas fa-chevron-right ml-2"/>
                        </CButton>                            
                      </CCol>
                    </CRow>                        
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <div class="logo_placeholder">
      <div v-if="environmentVariables.company_id_external && environmentVariables.company_image_id">
        <img v-if="cdnBaseUrl" :src="cdnBaseUrl + '/base/dashboard/' + environmentVariables.company_id_external + '/' + environmentVariables.company_image_id + '.png'"/>
      </div>
      <div v-else-if="environmentVariables.environment_id_external">
        <img v-if="environmentVariables.environment_tag === 'harryhr'" src="img/brand/harry_hr_logo_23_06_2022.png"/>
        <img v-if="environmentVariables.environment_tag === 'employalty'" src="img/brand/employalty_logo.png"/>
      </div>
    </div>
  </div>  
</template>

<script>
import axios from 'axios';

export default {
  name: 'ActivateLoyalty',
  data(){
    return {
      cdnBaseUrl: null,
      copyrightText: null,    
      background : {
        backgroundImage: "linear-gradient(45deg, rgba(0, 104, 89, 0.9), rgba(48, 146, 139, 0.95)), url('./img/login/harry_hr_pattern.png')",
        backgroundRepeat: 'repeat',
      },
      resetString: null,
      resetStringValidated: false,
      resetStringValid: false,
      passwordResetSuccessful: false,
      resetPasswordErrorMessage: null,
      resetPasswordData: {
        password1: null,
        password2: null
      },
      email: null,
      errorMessage: null,
      environmentVariables: {
        company_id_external: null,
        environment_id_external: null
      },
      companyVariables: {
        company_id_external: null
      }           
    }
  },
  methods: {
    getEnvironmentVariables() {
      // Get the hostname
      this.platformHostname = window.location.hostname;
      // Set the params
      let params = {};
      params.hostname = this.platformHostname;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/platform/environments/dashboard', params)
      .then(res => {
        this.environmentVariables = res.data.data;
        // Set the dashboard title
        document.title = this.environmentVariables.dashboard_title;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.environmentVariables.primary_color);        
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', this.environmentVariables.primary_color);
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', this.environmentVariables.secondary_color);
        // Set the dashboard font
        document.documentElement.style.setProperty('--DashboardFontFamily', this.environmentVariables.font_family);
        // Set the background image
        this.background.backgroundImage =  "linear-gradient(45deg, rgba(" + this.environmentVariables.primary_color_rgb + ", 0.85), rgba(" + this.environmentVariables.primary_color_rgb + ", 0.9)), url('./img/login/harry_hr_pattern.png')",
        // Set the copyright text
        this.copyrightText = this.environmentVariables.copyright;
        
        // Set the favicon href and the placeholder for the email field
        if(this.environmentVariables.environment_tag ==='harryhr') {
          document.querySelector("link[rel~='icon']").href = 'img/brand/harry_hr_favicon_23_06_2022.png';
          this.emailFieldPlaceholder = "📩 Hi@Harryhr.com";
        } else if(this.environmentVariables.environment_tag ==='employalty') {
          document.querySelector("link[rel~='icon']").href = 'img/brand/employalty_favicon.png';
          this.emailFieldPlaceholder = "📩 Hi@Employalty.com";
        }        
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    checkResetString() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/verification/loyalty/' + this.resetString)
      .then(res => {
        if(res.data.code === 'Reset link valid') this.resetStringValid = true;
        // Update the resetStringValidated value
        this.resetStringValidated = true;
      })
      .catch(err => {
        if(err.response.data.code === 'Reset link invalid') this.resetStringValid = false;
        // Update the resetStringValidated value
        this.resetStringValidated = true;
      });
    },
    resetPassword() {
      let params = {};
      // Set the params        
      params.resetPasswordData = this.resetPasswordData;
      // Reset the resetPasswordErrorMessage value
      this.resetPasswordErrorMessage = null;
      // Check the provided email address
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/verification/loyalty/' + this.resetString, params)
      .then(res => {
        if(res.data.code === 'Password reset succesful') this.passwordResetSuccessful = true;
        this.email = res.data.data;
        this.login();
      })
      .catch(err => {;
        if(err.response.data.code === 'No matching passwords') {
          this.resetPasswordErrorMessage = this.$t('login.Passwords_do_not_match');
        } else if(err.response.data.code === 'Invalid password length') {
          this.resetPasswordErrorMessage = this.$t('login.Use_longer_password');
        }
      });        
    },
    getCompanyVariables() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/variables/dashboard/company')
      .then(res => {
        this.companyVariables = res.data.data;
        // Set the dashboard title
        document.title = this.companyVariables.dashboard_title;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.companyVariables.environment_primary_color);
        // Set default color variables
        let primaryColor = null;
        let secondaryColor = null;
        // Set the primaryColor value
        (this.companyVariables.primary_color) ? primaryColor = this.companyVariables.primary_color : primaryColor = this.companyVariables.environment_primary_color;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', primaryColor);
        // Set the primaryColor value
        (this.companyVariables.secondary_color) ? secondaryColor = this.companyVariables.secondary_color : secondaryColor = this.companyVariables.environment_secondary_color;
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', secondaryColor);
        // Set the dashboard font
        document.documentElement.style.setProperty('--DashboardFontFamily', this.companyVariables.font_family);        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    login() {
      let email = this.email;
      let password = this.resetPasswordData.password1;
      this.$store.dispatch('login', { email, password })
      .then(res => {
        // Get the company platform variables
        this.getCompanyVariables();

        let authenticateUser = res.data.data.authenticate_user;
        let userAuthenticationSet = res.data.data.user_authentication_set;
        
        // Navigate to authentication setup page
        if(userAuthenticationSet === false) this.$router.push('/auth/setup');
        // Navigate to authentication code page
        else if(userAuthenticationSet === true && authenticateUser === true) this.$router.push('/auth/verify');
        // Navigate to dashboard homepage
        else this.$router.push('/')
      })
      .catch(err => {
        if(err.response.data.code === 'Not authorized') {
          this.errorMessage = this.$t('login.error_message');
        } else if(err.response.data.code === 'License deactivated') {
          this.errorMessage = this.$t('login.license_deactivated');
        }
        console.log(this.errorMessage);
      })
    },
    openLoginPage() {
      this.$router.push({path: '/login'});
    }    
  },
  mounted: function() {
    // Set the base URL for the CDN
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;    
    // Get the environment variables
    this.getEnvironmentVariables();

    if(this.$route.params.id) {
      // Set the resetString value
      this.resetString = this.$route.params.id;
      // Check the reset string
      this.checkResetString();     
    }  
  }
}
</script>
